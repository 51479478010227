<template>
  <div class="load-review-detail-container">
    <my-nav-bar
      :title="$route.query.delivery_name"
      left-text="返回"
      left-arrow
      right-text="操作"
      @click-right="handleClickRight"
    />
    <div class="filter">
      <form action="/">
        <van-search
          v-model="goodsName"
          placeholder="请输入商品名称"
          clearable
          @search="handleSearch"
          @clear="handleClear"
        />
      </form>
    </div>
    <div class="app-main-search-container">
      <div class="load-review-detail-container__content">
        <div v-for="(item, index) in (filterGoodsList.length > 0 ? filterGoodsList : goodses)" :key="index" class="load-review-detail-container__content__goods">
          <load-review-goods
            :goods="item"
            @load-review-goods="handleLoadReviewGoods"
            @image-preview="handleImagePreview"
          />
        </div>
      </div>
    </div>
    <div v-if="goodses.length > 0" class="fixed-bottom-btn load-review-detail-container__footer">
      <van-button :disabled="notLoadReviewCount > 0" square block @click="finished">
        <span v-if="notLoadReviewCount === 0">复核完成</span>
        <span v-else>还有{{ notLoadReviewCount }}种商品未复核</span>
      </van-button>
    </div>
    <van-dialog
      v-model="showInputDialog"
      title="输入复核数量"
      show-cancel-button
      confirm-button-text="确定"
      confirm-button-color="#1A6FF2"
      @confirm="loadReviewGoods"
    >
      <div class="load-review-detail-container__footer__input">
        <van-field v-if="goods.large_unit" v-model="loadReviewLargeVolume" type="number" :label="`${goods.large_unit}数`" :placeholder="`请输入${goods.large_unit}数`" />
        <van-field v-if="goods.least_unit" v-model="loadReviewLeastVolume" type="number" :label="`${goods.least_unit}数`" :placeholder="`请输入${goods.least_unit}数`" />
      </div>
    </van-dialog>
    <van-action-sheet v-model="showActionSheet" :actions="actions" @select="handleSelected" />
  </div>
</template>

<script>
import LoadReviewGoods from './components/goods'
import loadReviewApi, { checkNew } from '@/api/load_review'
import { now } from '@/utils'
import MyNavBar from '@/components/my-nav-bar'
import { ImagePreview } from 'vant'
export default {
  name: 'LoadReviewDetailContainer',
  components: { LoadReviewGoods, MyNavBar },
  data() {
    return {
      goodses: [],
      loadReviewLargeVolume: '',
      loadReviewLeastVolume: '',
      goods: {
        large_unit: '',
        least_unit: ''
      },
      showInputDialog: false,
      goodsName: '',
      filterGoodsList: [],
      actions: [
        { name: '取消复核', type: 1 },
        { name: '缺货商品', type: 2 },
        { name: '取消筛选', type: 3 }
      ],
      showActionSheet: false,
      orderCount: 0,
      timer: null
    }
  },
  computed: {
    notLoadReviewCount() {
      let notLoadReviewCount = this.goodses.length
      this.goodses.map(goods => {
        if (goods.load_review_finished_datetime) { notLoadReviewCount-- }
      })
      return notLoadReviewCount
    }
  },
  created() {
    this.getDetail()
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    getDetail() {
      const params = {
        delivery_id: this.$route.query.delivery_id
      }
      this.beginLoad()
      loadReviewApi.detail(params).then(res => {
        this.endLoad()
        this.goodses = res.data.list
        this.orderCount = res.data.order_count
        if (!this.timer) {
          this.timer = setInterval(() => {
            const params = {
              delivery_id: this.$route.query.delivery_id
            }
            checkNew(params).then(res => {
              if (res.data.order_count !== this.orderCount) {
                this.getDetail()
              }
            })
          }, 10000)
        }
      })
    },
    handleLoadReviewGoods(goods) {
      this.goods = goods
      this.loadReviewLargeVolume = goods.large_volume
      this.loadReviewLeastVolume = goods.least_volume
      this.showInputDialog = true
    },
    loadReviewGoods() {
      const data = {
        delivery_id: this.$route.query.delivery_id,
        client_id: this.goods.client_id,
        goods_id: this.goods.goods_id,
        load_review_large_volume: this.loadReviewLargeVolume,
        load_review_least_volume: this.loadReviewLeastVolume
      }
      this.beginLoad()
      loadReviewApi.goods(data).then(res => {
        this.endLoad()
        this.goods.load_review_finished_datetime = now()
        this.goods.load_review_large_volume = this.loadReviewLargeVolume
        this.goods.load_review_least_volume = this.loadReviewLeastVolume
        this.goods.is_load_review = 1
        if (this.goods.load_review_finished_datetime) {
          // 已完成的放到最下面
          const index = this.goodses.findIndex(goods => goods.goods_id === this.goods.goods_id)
          if (index !== -1) {
            const mvGoods = this.goodses[index]
            this.goodses.splice(index, 1)
            this.goodses.push(mvGoods)
          }
        }
      })
    },
    cancel() {
      this.confirm('确定取消复核吗？').then(() => {
        const data = {
          delivery_id: this.$route.query.delivery_id
          // client_id: this.$route.query.client_id
        }
        this.beginLoad()
        loadReviewApi.cancel(data).then(res => {
          this.success(res.msg)
          this.$router.go(-1)
        })
      })
    },
    handleClickRight() {
      this.showActionSheet = true
    },
    handleSelected(action) {
      if (action.type === 1) {
        this.cancel()
      } else if (action.type === 2) {
        this.filterGoodsList = this.goodses.filter(goods => {
          if (Number(goods.large_volume) !== Number(goods.load_review_large_volume) || Number(goods.least_volume) !== Number(goods.load_review_least_volume)) {
            return goods
          }
        })
      } else {
        this.filterGoodsList = []
      }
      this.showActionSheet = false
    },
    finished() {
      this.confirm('确定要提交复核结果吗？').then(() => {
        const data = {
          delivery_id: this.$route.query.delivery_id
        }
        this.beginLoad()
        loadReviewApi.finish(data).then(res => {
          this.success(res.msg)
          this.$router.back()
        })
      })
    },
    handleSearch() {
      this.filterGoodsList = this.goodses.filter(goods => {
        if (goods.name.search(this.goodsName) !== -1) {
          return goods
        }
      })
    },
    handleClear() {
      this.filterGoodsList = []
    },
    handleImagePreview(image) {
      ImagePreview([image])
    }
  }
}
</script>

<style lang="scss" scoped>
  .load-review-detail-container {
    &__content {
      padding: 15px 15px 55px 15px;
      &__goods {
        margin-bottom: 10px;
      }
    }
    &__footer {
      background-color: #fff;
      z-index: 99;
      &__input {
        padding: 10px 15px;
      }
      .van-button {
        height: 50px;
      }
    }
    .filter {
      z-index: 2;
      position: fixed;
      left: 0;
      right: 0;
      top: 46px;
    }
  }
</style>
