<template>
  <div class="load-review-goods-container">
    <div v-if="isFinished" class="load-review-goods-container__finished-logo">
      <van-image width="1.78667rem" height="1.6rem" :src="require('@/assets/images/yifuhe.png')" />
    </div>
    <van-cell title="单号" :value="goods.order_num" />
    <van-cell title="拣货人" :value="goods.picker?.name" />
    <van-cell title="客户" :value="goods.client?.name" value-class="client-name" />
    <div class="load-review-goods-container__content">
      <div class="load-review-goods-container__content-img" @click="$emit('image-preview', goods.image)">
        <van-image width="2.26666rem" height="2.26666rem" :src="goods.image" />
      </div>
      <div class="load-review-goods-container__content-text">
        <div class="load-review-goods-container__content-name">{{ goods.name }}<span v-if="goods.is_pick_shortage == '1'" class="tag">拣缺</span></div>
        <div class="load-review-goods-container__size-storage">
          <!--<span>储位：{{ goods.goods_warehouse.storage }}</span>-->
          <!--<i style="padding: 0 10px" />-->
          <span>规格：1*{{ goods.size }}</span>
        </div>
        <div class="load-review-goods-container__large-least">
          数量：
          <span v-if="goods.large_unit && goods.large_volume > 0">{{ goods.large_volume }}{{ goods.large_unit }}</span>
          <span v-if="goods.large_volume > 0 && goods.least_volume > 0" style="padding: 0 2px">+</span>
          <span v-if="goods.least_volume > 0">{{ goods.least_volume }}{{ goods.least_unit }}</span>
        </div>
      </div>
    </div>
    <van-row type="flex" align="center" class="load-review-goods-container__pick-info">
      <van-col :span="14">
        <div>
          <span>已复核：</span>
          <span v-if="goods.large_unit && goods.load_review_large_volume > 0">{{ goods.load_review_large_volume }}{{ goods.large_unit }}</span>
          <span v-if="goods.load_review_large_volume > 0 && goods.load_review_least_volume > 0" style="padding: 0 2px">+</span>
          <span v-if="goods.load_review_least_volume > 0">{{ goods.load_review_least_volume }}{{ goods.least_unit }}</span>
        </div>
        <div v-if="goods.is_load_review && (outLargeVolume > 0 || outLeastVolume > 0)" class="shortage">
          <span>缺<i style="padding: 0 7px" />货：</span>
          <span v-if="goods.large_unit && outLargeVolume > 0">{{ outLargeVolume }}{{ goods.large_unit }}</span>
          <span v-if="goods.outLargeVolume > 0 && goods.outLeastVolume > 0" style="padding: 0 2px">+</span>
          <span v-if="outLeastVolume > 0">{{ outLeastVolume }}{{ goods.least_unit }}</span>
        </div>
      </van-col>
      <van-col class="load-review-goods-container__pick-button" :span="10">
        <van-button color="#01ADFF" round @click="handleReview">确认复核</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { unitsToVolume, volumeToUnits } from '@/utils'
export default {
  name: 'LoadReviewGoods',
  props: {
    goods: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      outLargeVolume: 0,
      outLeastVolume: 0
    }
  },
  computed: {
    isFinished() {
      return this.goods.load_review_finished_datetime
      // &&
      //     Number(this.goods.large_volume) === Number(this.goods.load_review_large_volume) &&
      //     Number(this.goods.least_volume) === Number(this.goods.load_review_least_volume)
    }
  },
  watch: {
    goods: {
      handler: function() {
        this.calcOutVolume()
      },
      deep: true
    }
  },
  created() {
    this.calcOutVolume()
  },
  methods: {
    handleReview() {
      this.$emit('load-review-goods', this.goods)
    },
    calcOutVolume() {
      const pickVolume = unitsToVolume(this.goods.load_review_large_volume, this.goods.load_review_least_volume, this.goods.size)
      const outVolume = this.goods.volume - pickVolume
      const units = volumeToUnits(outVolume, this.goods.size)
      this.outLargeVolume = units.largeVolume
      this.outLeastVolume = units.leastVolume
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/styles/ellipsis";
  @import "src/styles/word-break";
  @import "src/styles/color";
  .load-review-goods-container {
    position: relative;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 6px 0 rgba(48, 48, 48, 0.07);
    &__content {
      display: flex;
      padding: 15px 15px 5px 15px;
    }
    &__content-img {
      .van-image {
        /*width: 85px;*/
        /*height: 85px;*/
      }
    }
    &__content-text {
      @extend %ellipsis;
      padding: 0 10px;
    }
    &__content-name {
      @extend %word-break;
      color: #1C1C1C;
      margin-bottom: 5px;
      .tag{
        margin-left:5px;
        padding: 1px 4px;
        background-color:#ee0a24;
        color: #fff;
        font-size: 12px;
      }
    }
    &__size-storage {
      color: #A3A3A3;
      margin-bottom: 5px;
    }
    &__pick-info {
      padding: 0 15px 15px 15px;
    }
    &__pick-button {
      text-align: right;
      .van-button {
        height: 30px;
        /*width: 85px;*/
      }
    }
    &__finished-logo {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      /*width: 80px;*/
      /*height: 80px;*/
      /*z-index: 10;*/
    }
    .shortage {
      color: $amountRedColor;
    }
  }
  .client-name {
    flex: 3;
  }
</style>
